.Footer {
    font-size: 1.25rem;
    text-align: left;

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
}

.FooterBox {
    padding: 4rem 1.5rem;
}

.FooterHeading {
    margin: 0.5rem 0;
}

.CompanyName {
    color:#AAA;
}

.BizDesc {
    color:#AAA;
    margin:0;
}

.LangBox {
    margin: 0.5rem 0 0.5rem 0;
}