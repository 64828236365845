.TopNavBox {
    color: black;

    width: 100%;
    padding-top: 1.8rem;
    height: 4.5rem;
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    align-items: center;
}

.TopNavC {
    display: block;
    text-align: left;
    margin-left:2rem;
}

.TopNavC .Title {
    font-weight: bold;
    color:#2bbbff;
}

.TopNavR {
    display: block;
    min-width: 180px;
    margin-right:2rem;
    text-align: right;
    font-weight: normal;
    flex: 1 1;
}

.TopNavR a {
    color:#3c3c3c;
    margin-left: 1.2rem;
}
