.App {
  text-align: center;
  font-family: Apercu,Helvetica,Arial,sans-serif;
  color:#3c3c3c;
}

.s-alert-box {
  z-index: 9999;
}

.Container {
  padding: 0 1.5em;
}

.Content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
}
